//@ts-nocheck
import React from 'react'
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement
} from 'react-stripe-elements'
import PropTypes from 'prop-types'
import { FiArrowRight } from 'react-icons/fi'
import { FaCcStripe } from 'react-icons/fa'
import styled from 'styled-components'
import axios from 'axios'
import { navigate } from 'gatsby'
import { GooSpinner } from 'react-spinners-kit'

import { useCartStore, useMagic } from 'stores'
import { Body, Button } from 'components'
import { CartContext } from 'contexts'
import Form from './Form'
import { StripeCharge } from 'models'

const StripeInputGroup = styled.div`
  border-bottom: 1px solid #8b8e96;
  color: white;
  transition: all ease 0.3s;
  padding: 0.5rem;
  outline: none;
  background: none;
  margin: 1rem 0;
  &:focus {
    border-bottom: 1px solid #1373e6;
  }
`

const StyledButton = styled(Button)`
  &&& {
    bottom: 2rem;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
  }
`

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isPaymentProcessing: false,
      errorMsg: null
    }
  }

  componentDidMount() {
    this.setState({
      isLoading: false
    })
  }

  handleError = msg => {
    this.setState({
      errorMsg: msg,
      isPaymentProcessing: false
    })
  }

  handleChange = e => {
    this.setState({
      [e.elementType]: e.complete,
      errorMsg: ''
    })
  }

  handleSubmit = async ev => {
    ev.preventDefault()
    const { cardNumber, cardExpiry, cardCvc, isPaymentProcessing } = this.state
    const { activeCoupon } = this.props
    const cart = useCartStore.getState().cart

    if (!isPaymentProcessing) {
      if (cardNumber && cardExpiry && cardCvc) {
        this.setState({
          isPaymentProcessing: true
        })
        let { token } = await this.props.stripe.createToken({
          name: useMagic.getState().user.email
        })
        try {
          const response: StripeCharge = await axios.post(
            '/.netlify/functions/charge',
            {
              purchasedItemIds: cart.map(item => item.contentful_id),
              email: useMagic.getState().user.email,
              coupon: activeCoupon,
              token: token.id
            }
          )

          if (typeof window !== 'undefined') {
            window.dataLayer.push({
              event: 'Purchase',
              transactionId: response.data.id,
              transactionTotal: response.data.amount / 100,
              transactionProducts: response.data.items
            })
          }
          useCartStore.getState().clearCart()
          navigate('/thankyou')
        } catch (err) {
          if (err.response) {
            this.handleError(err.response.data)
          } else {
            console.log(err)
          }
          //handles stripe rejection errors, not auth0 errors
        }
      } else {
        this.setState({
          errorMsg: "Make sure you've filled in all fields"
        })
      }
    }
  }

  render() {
    return (
      <form
        data-testid="stripe-form"
        onSubmit={this.handleSubmit}
        style={{
          height: '82%',
          display: 'flex',
          alignItems: 'stretch',
          justifyContent: 'space-between',
          flexDirection: 'column'
        }}
      >
        <div>
          <Form.Label>Card Number</Form.Label>
          <StripeInputGroup>
            <CardNumberElement
              style={{ base: { fontSize: '20px', color: 'white' } }}
              onChange={this.handleChange}
            />
          </StripeInputGroup>

          <Form.InputGroup>
            <div>
              <Form.Label>Expiry Date</Form.Label>
              <StripeInputGroup>
                <CardExpiryElement
                  style={{ base: { fontSize: '20px', color: 'white' } }}
                  onChange={this.handleChange}
                />
              </StripeInputGroup>
            </div>
            <div>
              <Form.Label>CVC</Form.Label>
              <StripeInputGroup>
                <CardCVCElement
                  style={{ base: { fontSize: '20px', color: 'white' } }}
                  onChange={this.handleChange}
                />
              </StripeInputGroup>
            </div>
          </Form.InputGroup>
          <Body variant="sm" color="white">
            <FaCcStripe style={{ paddingRight: '1rem' }} />
            Secure payments with Stripe
          </Body>
          {this.state.errorMsg ? <p>{this.state.errorMsg}</p> : null}
        </div>
        <StyledButton
          onClick={e => this.handleSubmit(e)}
          data-testid="purchase-btn"
        >
          {this.state.isPaymentProcessing ? (
            <GooSpinner size={30} color="white" />
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              Confirm order <FiArrowRight style={{ height: '1rem' }} />
            </div>
          )}
        </StyledButton>
      </form>
    )
  }
}
CheckoutForm.propTypes = {
  cart: PropTypes.array,
  clearCart: PropTypes.func,
  stripe: PropTypes.object
}
CheckoutForm.contextType = CartContext
export default injectStripe(CheckoutForm)
