import React from 'react'

import { Banner } from '../components'

const initialState = {
  isBannerActive: false,
  bannerRedirect: '',
  bannerMsg: '',
  showNotification: (msg: string, url?: string) => {}
}

export const NotificationContext = React.createContext(initialState)

export const NotificationConsumer = NotificationContext.Consumer

export class NotificationProvider extends React.Component {
  state = {
    active: false,
    redirect: '',
    msg: ''
  }

  closeBanner = () => {
    this.setState({
      active: false,
      redirect: ''
    })
  }

  showNotification = (msg: string, url?: string) => {
    this.setState({
      active: true,
      msg: msg,
      redirect: url
    })
    setTimeout(() => {
      this.closeBanner()
    }, 8000)
  }
  render() {
    const { active, redirect, msg } = this.state
    return (
      <NotificationContext.Provider
        value={{
          isBannerActive: active,
          bannerRedirect: redirect,
          bannerMsg: msg,
          showNotification: this.showNotification
        }}
      >
        {this.props.children}
      </NotificationContext.Provider>
    )
  }
}

export default NotificationContext
