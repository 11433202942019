import React from 'react'
import { itemExistsInCart } from 'utils/helpers'

import {
  NotificationConsumer,
  NotificationContext
} from './NotificationContext'
import { BookType } from 'models'

export type CartItemT = {
  contentful_id: string
  id: string
  price: number
  title: string
  isPreorder?: boolean
}

const initialState: {
  cart: CartItemT[]
  addToCart: (_: BookType) => void
  removeFromCart: (_: string) => void
  clearCart: () => void
} = {
  cart: [],
  addToCart: () => {},
  removeFromCart: () => {},
  clearCart: () => {}
}

export const CartContext = React.createContext(initialState)

export const CartConsumer = CartContext.Consumer

export class CartProvider extends React.Component {
  state = {
    cart: [] as any
  }
  componentDidMount() {
    //checks if there's a cart already in user's local storage
    if (localStorage.getItem('cart')) {
      this.setState({
        cart: JSON.parse(localStorage.getItem('cart'))
      })
    } else {
      //always makes sure that the cart state is an array
      this.setState({
        cart: []
      })
    }
  }
  addToCart = (book: BookType) => {
    if (itemExistsInCart(book.id)) {
      this.context.showNotification(
        '😕 Hmm...looks like this item is already in your cart'
      )
    } else {
      this.setState(
        {
          cart: [
            ...this.state.cart,
            {
              id: book.id,
              contentful_id: book.contentful_id,
              title: book.title,
              isPreorder: book.availability === 'preorder',
              price: book.price
            }
          ]
        },
        () => {
          localStorage.setItem('cart', JSON.stringify(this.state.cart))
          this.context.showNotification('🎉 Added to your cart!', '/cart')
        }
      )
    }
  }

  removeFromCart = (id: string) => {
    this.setState(
      {
        cart: this.state.cart.filter(
          (item: BookType) => item.contentful_id !== id
        )
      },
      () => {
        localStorage.removeItem('cart')
        localStorage.setItem('cart', JSON.stringify(this.state.cart))
      }
    )
  }

  clearCart = () => {
    this.setState({
      cart: []
    })
  }
  render() {
    return (
      <NotificationConsumer>
        {() => (
          <CartContext.Provider
            value={{
              cart: this.state.cart,
              addToCart: this.addToCart,
              removeFromCart: this.removeFromCart,
              clearCart: this.clearCart
            }}
          >
            {this.props.children}
          </CartContext.Provider>
        )}
      </NotificationConsumer>
    )
  }
}

CartProvider.contextType = NotificationContext
export default CartContext
