import React, { useState, useEffect } from 'react'
import { Elements } from 'react-stripe-elements'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { useMagic } from 'stores'
import { createUser } from 'utils/auth'
import { doesUserExist } from 'utils'
import { Button, Stack, Heading, Body, CircularLoader } from 'components'
import InjectedCheckoutForm from 'components/stripeInjectForm'
import { Component, TextField, CloseButton, Link } from './style'

type Props = {
  closeCheckout: () => void
  activeCoupon: string
}

//TODO: rewrite this component

export const Checkout = ({ closeCheckout, activeCoupon }: Props) => {
  const [mode, setMode] = useState<'createAccount' | 'signIn'>('createAccount')
  const { isAuthenticated, signIn, magic } = useMagic()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    magic.preload()
  }, [])
  const schema = Yup.object().shape({
    email: Yup.string()
      .required('This field is required')
      .email("Hmm...this doesn't look like a valid email")
  })
  const renderForm = () => {
    if (isAuthenticated) {
      //user is logged in. This condition overrides all other conditions
      return (
        <>
          <Heading variant="sm" color="white">
            Card Details
          </Heading>
          <Body color="white">
            Type in your card details below to complete your purchase
          </Body>
          <Elements>
            <InjectedCheckoutForm activeCoupon={activeCoupon} />
          </Elements>
        </>
      )
    } else {
      return (
        <>
          <Heading variant="sm" color="white">
            {mode === 'createAccount' ? 'Create an account' : 'Login'}
          </Heading>
          <Body color="white">
            {mode === 'createAccount'
              ? "You'll need to create an account to read your ebooks. Type your email address below"
              : 'Type in your email below to log in'}
          </Body>
          <Link
            onClick={() =>
              mode === 'createAccount'
                ? setMode('signIn')
                : setMode('createAccount')
            }
          >
            {mode === 'createAccount'
              ? 'I already have an account'
              : "I don't have an account yet"}
          </Link>
          <Formik
            initialValues={{ email: '' }}
            validationSchema={schema}
            onSubmit={async (values, actions) => {
              setIsLoading(true)
              const email = values.email.toLowerCase().trim()
              const userExists = await doesUserExist(email)
              if (userExists) {
                if (mode === 'createAccount') {
                  actions.setFieldError(
                    'email',
                    'This user seems to exist already. Did you mean to sign in?'
                  )
                  setIsLoading(false)
                } else {
                  signIn({
                    email
                  })
                }
              } else {
                if (mode === 'signIn') {
                  actions.setFieldError(
                    'email',
                    "This user doesn't seem to exist yet. Did you mean to create an account? "
                  )
                  setIsLoading(false)
                } else {
                  const res = await createUser(email)
                  if (res.status === 200) {
                    signIn({
                      email
                    })
                  }
                }
              }
            }}
            render={({ errors, handleChange, values }) => (
              <Form>
                <Stack width="100%">
                  <TextField
                    className="input"
                    label="Email Address"
                    onChange={handleChange}
                    name="email"
                    placeholder="jane.smith@gmail.com"
                    InputLabelProps={{
                      htmlFor: 'email'
                    }}
                    value={values.email}
                    error={!!errors.email}
                    inputProps={{
                      'data-testid': 'email-input'
                    }}
                    helperText={
                      errors.email
                        ? errors.email
                        : "Type in your email, and we'll send a magic link to your email"
                    }
                  />
                  <Button type="submit" disabled={isLoading}>
                    Get Magic Link
                    {isLoading && (
                      <CircularLoader data-testid="circle-loader" />
                    )}
                  </Button>
                </Stack>
              </Form>
            )}
          />
        </>
      )
    }
  }

  return (
    <Component data-testid="checkout">
      <div>{renderForm()}</div>
      <CloseButton onClick={closeCheckout} />
    </Component>
  )
}

export default Checkout
