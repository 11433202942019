//@ts-nocheck
import styled from 'styled-components'
import { FiX } from 'react-icons/fi'
import { client } from 'services/apollo'
import { Form } from 'components'

export const Component = styled.div`
  background-color: #2c2d31;
  padding: 3rem 2rem;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.25);
  color: white;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  align-items: stretch;
  position: relative;
`

export const TextField = styled(Form.TextField)`
  &&& {
    width: 100%;
    color: white !important;
    & > .MuiInput-underline:before {
      border-bottom: 1px solid white !important;
    }
    & > * {
      color: white !important;
    }

    > & .MuiInput-underline:after {
      border-bottom-color: ${props => props.theme.colors.blues.blue40};
    }
    & > .MuiInput-underline.Mui-error:after {
      border-bottom-color: ${props => props.theme.colors.error};
    }
    & > .MuiFormLabel-root.Mui-focused {
      color: ${props => props.theme.colors.blues.blue40};
    }
    & > .MuiInputLabel-root {
      color: white !important;
    }
    & > .MuiFormLabel-root.Mui-error.Mui-focused {
      color: ${props => props.theme.colors.error};
    }
    & > .MuiFormLabel-root.Mui-error {
      color: ${props => props.theme.colors.error};
    }

    & > .MuiFormHelperText-root .Mui-error {
      color: ${props => props.theme.colors.error};
    }
  }
`

export const CloseButton = styled(FiX)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  font-size: 1.5rem;
`

export const Link = styled.p`
  font-style: italic;
  font-weight: lighter;
  cursor: pointer;
  margin-bottom: 0;
  transition: all ease 0.3s;
  color: white;
  margin-bottom: 1rem;
  &:hover {
    color: #e81e61;
  }
`

export default TextField
